.oauth-actions {
  width: 100%;

  .credential-cell {
    cursor: default;
    display: flex !important;
    align-items: center !important;
    height: 100% !important;
  }

  .credential-actions {
    max-width: 120px;
    cursor: default;
    display: flex;
    align-items: center;
    gap: 1rem;
  }

  .icon-button {
    background-color: transparent;
    cursor: pointer;
    padding: 8px;
    border-radius: 9999px;
    border-color: transparent;
    transition: colors 0.2s ease-in-out;

    svg {
      width: 20px;
      height: 20px;
    }

    &:disabled {
      opacity: 0.5;
      cursor: not-allowed;
    }

    &--refresh {
      color: #059669; // green-600 equivalent
      &:hover:not(:disabled) {
        color: #047857; // green-700 equivalent
      }
    }

    &--rotate {
      color: #4b5563; // gray-600 equivalent
      &:hover:not(:disabled) {
        color: #374151; // gray-700 equivalent
      }
    }

    &--delete {
      color: #dc2626; // red-600 equivalent
      &:hover:not(:disabled) {
        color: #b91c1c; // red-700 equivalent
      }
    }
  }
}

.oauth-client-secret-actions {
  display: flex;
  justify-content: justify-start;
  gap: 1rem;
}

.row {
  margin-bottom: 0;
}
